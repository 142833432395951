import React from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col, Card } from "react-bootstrap";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import ContactForm from "../components/forms/contact";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// export const TrainingVideosTemplate = ({ title, titleSEO, imageContainer, content }) => (
export const TrainingVideosTemplate = ({ title, titleSEO, imageContainer, content }) => (
  <Layout>
    <Container className="d-flex flex-column justify-content-center align-items-center">
      <Row className="text-center my-5 py-5">
        <h1>Training Videos</h1>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="">These videos are available to premium subscribers.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

const TrainingVideos = ({ data }) => {
  // const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <TrainingVideosTemplate
      // title={frontmatter.title}
      // titleSEO={frontmatter.titleSEO}
      // imageContainer={frontmatter.imageContainer}
      // content={frontmatter.content}
      />
    </Layout>
  );
};

export default TrainingVideos;
